/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAuth from '../hooks/useAuth';
import heart from './../images/white-heart.png';

const NoSite = () => {

    const { user, setUser } = useAuth();

    // if (!user?.object) {
    //     window.location.replace('/');
    // }

    return (
        <div className='animated animatedFadeInUp fadeInUp my-12 screener-banner px-2'>
            <div className='header'>
            Thank you for your or your child's interest in the NextCOVE Study.
            </div>
            <div className='sub-header'>
            We value your or your child's interest to contribute to clinical research through participation. 
            While it appears that you or your child meet preliminary criteria, we unfortunately do not have a 
            site location near you or your child. We will contact you if a site becomes available in the future.
            </div>
            <div className='sub-header'>
                Thank you and have a great day.
            </div>
            <hr />
            <img alt='CVS heart logo' className='white-heart pb-10' src={heart} />
        </div>
    )
};

export default NoSite;