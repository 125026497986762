/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import doIQualify from '../images/Do I Qualify Button@2x.png';
import joinUsHero from '../images/cta-background@2x.png';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import '../styles/JoinUs.css';

const questionnaireLink = '/questionnaire';

const JoinUs = () => {
    const { doConversion } = useAuth();
    const navigate = useNavigate();

    const nav = (page) => {
        if (page === questionnaireLink)
            doConversion();
        navigate(page);
    };

    return (
        <section 
                className='join-us-section'
                style={{
                    minHeight: 651,
                    background: `transparent url('${joinUsHero}') 0% 0% no-repeat padding-box`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 1,
                }}
            >
                <div className='content'>
                    <p className='header'>
                    The Fight Against COVID-19 isn’t Over
                    </p>
                    <p className='info'>
                    You or a loved one may be eligible to receive access to an investigational booster vaccine for the potential continued protection against COVID-19 by participating in a clinical trial.
                    </p>
                    <p>
                        <div onClick={() => nav('/questionnaire')} className='conversion-button do-i-qualify-button p-[13px]'>
                            <span>Pre-Qualify Now</span>
                        </div>
                    </p>
                </div>
            </section>
    )
};

export default JoinUs;