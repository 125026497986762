/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import hero from '../images/hero-background@2x.png';
import heart from '../images/heart-icon@2x.png';
import impactHero from '../images/impact-of-nextcove@2x.png';
import howItWorksHero from '../images/participate@2x.png';
import doIQualify from '../images/Do I Qualify Button@2x.png';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import Risk from './RiskSection';
import JoinUs from './JoinUs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useAuth from '../hooks/useAuth';
import '../styles/About.css';

const questionnaireLink = '/questionnaire';

const About = () => {
    const { doConversion } = useAuth();

    const [showLoading, setShowLoading] = useState(true);

    const navigate = useNavigate();

    const nav = (page) => {
        //if (page === questionnaireLink)
            //doConversion();
        navigate(page);
    };

    const handleLoad = () => {
        setShowLoading(false);
    }
    
    return (
        <div class='landing animated animatedFadeInUp landing-fade-delay'>
            <section 
                className='about-banner-section'
                style={!showLoading ? {
                    
                    background: `transparent url('${hero}') 0% 0% no-repeat padding-box`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 1,
                } : {}}
            >
                <div className='content'>
                    <Row gutter={16}>
                        <Col xs={0} sm={0} md={0} lg={10} xl={8} xxl={12}>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={12}>
                            
                                <p className='header'>
                                About the NextCOVE COVID-19 Study
                                </p>
                                <p className='header'>
                                    <hr />
                                </p>
                                <p className='info'>
                                Get access to an investigational booster vaccine aimed at the continued potential protection against COVID-19 through a clinical trial.
                                </p>
                                <p>
                                    <div onClick={() => nav('/questionnaire')} className='conversion-button do-i-qualify-button p-[13px]'>
                                        <span>Pre-Qualify Now</span>
                                    </div>
                                </p>
                        </Col>
                    </Row>
                </div>
            </section>
            <Risk />
            <section 
                className='about-about-section'
            >
                <div className='content'>
                    <Row gutter={16}>
                        <Col xl={11} xxl={11}>
                            <img className='heart-logo' src={heart} />
                            <p className='header'>About Our NextCOVE COVID-19 Study</p>
                           
                        </Col>
                        <Col xl={13} xxl={13}>
                            <div className='info'>
                                <p>
                                The NextCOVE study is evaluating an investigational booster vaccine for COVID-19. 
                                The study is being used to get a better understanding of the effects of the 
                                investigational booster vaccine on potentially reducing the risk of COVID-19 
                                in participants 12 years of age and older. Participants will be randomized 
                                into several groups. Participants must have received a previous COVID-19 
                                vaccine and if over 18 years old, at least 1 booster vaccine. Researchers 
                                want to find out if the investigational booster vaccine is safe and effective 
                                for the continued potential protection against COVID-19.
                                </p>
                                <br />
                                <p> 
                                Participants will receive one investigational booster injection at the beginning of 
                                the study. Study participation is 12 months for each participant enrolled including 
                                several follow up visits. All study related health tests and medical checks will 
                                be provided by the study team.
                                </p>
                                <div className='sub-header'>
                                    Find out if you qualify and learn more today
                                </div>
                                <div onClick={() => nav('/questionnaire')} className='conversion-button do-i-qualify-button p-[13px]'>
                                    <span>Pre-Qualify Now</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='impact-content-section'>
                <div className='content'>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={12}>
                            <img className='impact-graphic' alt='hug graphic' src={impactHero} />
                        </Col>
                        <Col lg={13} xl={13} xxl={12}>
                            <p className='header'>Impact of The NextCOVE COVID-19 Study</p>
                            <div className='info'>
                                <p>
                                Participation in this study will help us advance research. Participants will contribute 
                                to the process of researching a new investigational booster vaccine for the potential 
                                continued protection against COVID-19. They may also benefit from access to medical 
                                assessments/evaluations relating to the study procedures, access to study doctors, 
                                trial related medical visits, physical exams, and more. This investigational study 
                                vaccine is being researched for the potential continued protection of COVID-19 in 
                                adolescents and adults 12 years of age and older. Join us in the continued fight 
                                against COVID-19.
                                </p>
                                <p> 
                                    <br />
                                    <div onClick={() => nav(questionnaireLink)} className='enroll-today-button-inverted p-[13px]'>
                                        <span >Enroll Today</span>
                                    </div>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='how-it-works-section'>
                <div className='content'>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <p className='header'>How to Participate</p>
                            <hr />
                            <p className='info'>
                            If you or someone you know meet the requirements, you may be eligible to participate in 
                            this clinical research study.
                            </p>
                            <br />
                            <div onClick={() => nav('/questionnaire')} className='conversion-button do-i-qualify-button p-[13px]'>
                                <span>Pre-Qualify Now</span>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <LazyLoadImage className='how-it-works-hero' alt='How it works graphic' src={howItWorksHero} />
                        </Col>
                    </Row>
                </div>
            </section>
            <JoinUs />
            {showLoading && <img width={2} alt='loading' src={hero} onLoad={handleLoad} />}
        </div>
    )
};

export default About;