import React, { useEffect, useLayoutEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import ContextProvider from '../../auth/Auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import queryString from 'query-string';
import { ConfigProvider } from 'antd';
import { setQSessionValue } from 'serva-questionnaire-render';
import ReactGA from 'react-ga4';

const enableLock = false;
const queryClient = new QueryClient();


/**
 * Auto-scroll to top on page transition
 * @param {props.children} App 
 * @returns 
 */
const Wrapper = ({ children }) => {
    const { setProgress, isAuthenticated, setExternalId } = useAuth();
    const nav = useNavigate();
    const location = useLocation();

    const checkLock = () => {
        
        if (enableLock && !isAuthenticated && location.pathname !== '/login') 
            nav('/login', { state: { route: location.pathname + location.search } });
    }
    
    checkLock();

	useLayoutEffect(() => {
        checkLock();
	  	document.body.scrollTo(0, 0);
        window.scrollTo(0, 0);
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
        setProgress();
        const { search } = location;
        const params = queryString.parse(search);
        if (params.oppty_id) {
            setQSessionValue('7743', params.oppty_id);
            setQSessionValue('6967', params.oppty_id);
        }
        if (window.location.hostname.toLowerCase().endsWith('cvscovidtrial.com')) {
            setQSessionValue('7756', 'Call Center');
            setQSessionValue('6982', 'Call Center');
        }
        if (window.location.hostname.toLowerCase().endsWith('cvscovid19study.com')) {
            setQSessionValue('7756', 'Direct Mail');
            setQSessionValue('6982', 'Direct Mail');
        }
	}, [location.pathname, setExternalId]);

    useEffect(() => {
        checkLock();
    }, []);

	return children
} 


const MainLayout = () => {
    ReactGA.initialize('G-MR1DGBTJZJ');

    return (
        <ConfigProvider 
			theme={{
				token: {
					borderRadius: 3
				}
		  	}}
		>
            <QueryClientProvider client={queryClient}>
                <ContextProvider>
                    <Navbar />
                    <Wrapper>
                        <Outlet />
                    </Wrapper>
                    <Footer />
                </ContextProvider>
            </QueryClientProvider>
        </ConfigProvider>
    );
};

export default MainLayout;