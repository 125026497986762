/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import heart from './../images/white-heart.png';
import useAuth from '../hooks/useAuth';

const InconvenientSite = () => {

    const { user, setUser } = useAuth();

    // if (!user?.object) {
    //     window.location.replace('/');
    // }

    return (
        <div className='animated animatedFadeInUp fadeInUp my-12 screener-banner'>
            <div className='header'>
                Sorry, there are no clinical research sites in your area.
            </div>
            <div className='sub-header'>
                We appreciate your interest in the study. <br />
                We are sorry there are no sites that are convenient for you.<br />
                Thank you for your interest and have a great day.
            </div>
            <hr />
            <img alt='CVS heart logo' className='white-heart pb-10' src={heart} />
        </div>
    )
};

export default InconvenientSite;