/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAuth from '../hooks/useAuth';
import heart from './../images/white-heart.png';

const DQ = () => {

    const { user, setUser } = useAuth();

    // if (!user?.object) {
    //     window.location.replace('/');
    // }

    return (
        <div style={{ marginTop: '3.5rem' }} className='animated animatedFadeInUp fadeInUp my-12 screener-banner'>
            <div className='header'>
                Thank You
            </div>
            <div className='sub-header max-w-[850px] m-auto'>
                <p>
                We appreciate your or your child's interest in the NextCOVE Study.
                </p>
                <br />
                <p>
                Unfortunately, based upon the information provided, it appears that you or your child do not meet the eligibility requirements for study participation.  
                </p>
                <br />
                <p>
                    To qualify for this study, a person must be:
                    <ul className=''>
                        <li>- 12 years of age or older</li>
                        <li>- Has previously received COVID-19 vaccinations</li>
                        <li>- Otherwise in good health</li>
                    </ul>
                </p>
                <br />
                <p>
                    If you know of another person who would be interested in participating, 
                    please forward the link <a target='_blank' className='underline hover:underline' href='#' rel="noreferrer">(www.cvscovidstudy.com)</a> to them so they can learn more about the study.
                </p>
                <br />
                <p>We also encourage you to click <a className='underline hover:underline' href='https://www.cvsclinicaltrials.com' target='_blank'>here</a> for more information about CVS Health Clinical Trial Services.</p>
            </div>
            <hr />
            <div className='info'>Thank you and have a great day.</div>
            <img alt='CVS heart logo' className='white-heart pb-10' src={heart} />
        </div>
    )
};

export default DQ;