/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAuth from '../hooks/useAuth';
import heart from './../images/white-heart.png';

const AlreadyScreened = () => {

    const { user, setUser } = useAuth();

    // if (!user?.object) {
    //     window.location.replace('/');
    // }

    return (
        <div className='animated animatedFadeInUp fadeInUp my-12 screener-banner'>
            <div className='header'>
            Thank you.
            </div>
            <div className='sub-header'>
            You or your child have already pre-screened for the NextCOVE Study.
            </div>
            <hr />
            <div className='info'>Thank you for your or your child's interest. </div>
            <img alt='CVS heart logo' className='white-heart pb-10' src={heart} />
        </div>
    )
};

export default AlreadyScreened;