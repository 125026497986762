import React from 'react';
import { Button } from 'antd';
import logo from '../../images/logo@2x.png';
import { MenuOutlined } from '@ant-design/icons';
import doIQualify from '../../images/Do I Qualify Button@2x.png';
import { useNavigate } from 'react-router-dom';

const activeNavs = ['/', '/questionnaire', '/about', '/clinical-research']

const Navbar = () => {

    const navigate = useNavigate();

    const [navbarOpen, setNavbarOpen] = React.useState(false);

    const nav = (path) => {
        setNavbarOpen(false);
        navigate(path);
    }

    return (
        <>
            <nav id='navbar' className="relative flex items-center justify-between px-2">
                <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                    <div className="w-full relative flex items-center justify-between md:w-auto md:static md:block md:justify-start">
                        <button
                            className="leading-relaxed inline-block py-2 whitespace-nowrap uppercase text-white"
                            onClick={() => nav('/')}
                        >
                            <img alt='CVS logo' src={logo} className='logo' />
                        </button>
                        <Button
                            className="cursor-pointer text-xl leading-none border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                            icon={<MenuOutlined />}
                            size='large'
                        >
                            
                        </Button>
                    </div>
                    <div
                        className={
                        "md:flex flex-grow items-center 2xl:height-[113px] xl:height-[113px]" +
                        (navbarOpen ? " flex" : " hidden")
                        }
                        id="example-navbar-danger"
                    >
                        <ul id="nav-list" className="flex flex-col md:flex-row list-none md:ml-auto transition-colors duration-300 ">
                            <li className="nav-item">
                                <button 
                                    type='link'
                                    className="px-3 py-2 flex items-center uppercase leading-snug hover:opacity-75" 
                                    onClick={() => nav('/')}
                                >
                                    <span className="lg:ml-2">home</span>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button 
                                    type='link'
                                    className="px-3 py-2 flex items-center uppercase leading-snug hover:opacity-75" 
                                    onClick={() => nav('/about')}
                                >
                                    <span className="lg:ml-2">about the nextcove covid-19 study</span>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button 
                                    type='link'
                                    className="px-3 py-2 flex items-center uppercase leading-snug hover:opacity-75" 
                                    onClick={() => nav('/faqs')}
                                >
                                    <span className="lg:ml-2 md-ml-0">faq<span className='lowercase'>s</span></span>
                                </button>
                            </li>
                            <li className="nav-item ml-3">
                                <img 
                                    alt='Do I Qualify Button' 
                                    className='m-auto do-i-qualify-button' src={doIQualify} 
                                    onClick={() => nav('/questionnaire')}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
};

export default Navbar;