/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAuth from '../hooks/useAuth';
import heart from './../images/white-heart.png';

const DeclinedParticipation = () => {

    const { user, setUser } = useAuth();

    // if (!user?.object) {
    //     window.location.replace('/');
    // }

    return (
        <div className='animated animatedFadeInUp fadeInUp my-12 screener-banner'>
            <div className='header'>
                Thank You
            </div>
            <div className='sub-header max-w-[700px] m-auto'>
                <p>
                We appreciate your or your child's interest in the NextCOVE Study. We are sorry you or your child 
                have declined to participate. If you or your child change your or their mind in the future, 
                please feel free to re-enter your or your child's information but we request that you or they 
                use a different email address.
                </p>
                <br />
            </div>
            <hr />
            <div className='info'>Thank you for your interest.</div>
            <img alt='CVS heart logo' className='white-heart pb-10' src={heart} />
        </div>
    )
};

export default DeclinedParticipation;