import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setQSessionValue } from 'serva-questionnaire-render';

const SourceCallCenter = () => {

    const nav = useNavigate();

    useEffect(() => {
        const allowedDoms = ['demo.retainbi.com', 'cvscovidstudy.com', 'cvscovid19study.com', 'cvscovidtrial.com'];
        const currDom = window.location.hostname.toLowerCase();
        if (allowedDoms.some(x => currDom.endsWith(x))) {
            setQSessionValue('7756', 'Call Center');
            setQSessionValue('6982', 'Call Center');
        }

        nav('/', { replace: true });
    }, [nav]);

    return <></>;
};

export default SourceCallCenter;