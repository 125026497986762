/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import hero from '../images/faq-hero@2x.png';
import clickToLearn from '../images/click-to-learn-more-button@2x.png';
import faqLearnMorePic from '../images/FAQ-learn-study@2x.png';
import downArrow from '../images/down-arrow.svg'
import { useNavigate } from 'react-router-dom';
import { Col, Collapse, Row } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import JoinUs from './JoinUs';
import Risk from './RiskSection';
import '../styles/FAQ.css';
const faqitems = [
    {
        title: 'What is a clinical research study?',
        description: (
            <p>
                A clinical trial, also called a clinical research study, is a medical study to help doctors and scientists learn more about 
                the potential for a new or existing medication, device, or procedure. Clinical trials are designed to learn if an 
                investigational medication, device, or procedure is safe, study how it works in the body, and if it is effective in 
                the treatment of a specific condition.
            </p>
        )
    },
    {
        title: 'Can I leave the study if I change my mind?',
        description: (
            <p>
                Participation in any clinical research study is always completely voluntary. Taking part in this study is your choice, 
                and you may leave the study at any time for any reason.
            </p>
        )
    },
    {
        title: 'What should I expect if I choose to take part in this clinical study?',
        description: (
            <p>
               Participation in this study consists of several visits with the study team. Those who qualify may receive 
               the investigational booster vaccine. You may also be compensated for your study-related time.
            </p>
        )
    },
    {
        title: 'How long will study participation last?',
        description: (
            <p>
                The duration of this study for all participants is 12 months. 
            </p>
        )
    },
    {
        title: 'What is the investigational medication?',
        description: (
            <p>
                The NextCOVE Study is evaluating an investigational booster vaccine known as mRNA-1283.222.
            </p>
        )
    },
    {
        title: 'Will my medical and personal information be kept private?',
        description: (
            <p>
                All data will be kept private and shared on a need-to-know basis with the study team.
            </p>
        )
    },
    {
        title: 'Does participating in this study cost me anything?',
        description: (
            <p>
                There is no cost to participate in the study.  If you qualify, you may be compensated for your participation. 
            </p>
        )
    },
    {
        title: 'How did you get my information?',
        description: (
            <p>
                CVS Health Clinical Trial Services™ is one of many businesses owned and operated by CVS Health®.   
                You may have visited or provided your contact information to one of our CVS Pharmacies, for example.  
                One of the services that CVS Health Clinical Trials Services™ provides is to make individuals with 
                relationships to CVS Health® entities aware of clinical research studies that may be of interest to them.
            </p>
        )
    }
];

const FAQ = () => {

    const [showLoading, setShowLoading] = useState(true);

    const nav = useNavigate();

    const handleLoad = () => {
        setShowLoading(false);
    }
    
    return (
        <div class='landing animated animatedFadeInUp landing-fade-delay'>
            <section 
                className='faq-banner-section'
                style={!showLoading ? {
                    
                    background: `transparent url('${hero}') 0% 0% no-repeat padding-box`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 1,
                } : {}}
            >
                <div className='content'>
                    <p className='header'>
                    Frequently Asked Questions (FAQs)
                    </p>
                </div>
            </section>
            <Risk />
            <section 
                className='faq-about-section'
            >
                <div className='content'>
                    <Row gutter={17}>
                        <Col lg={12} xl={12} xxl={12}>
                            <p className='header'>Learn More About Our Research Study</p>
                            <p className='info'>
                            For more information about the specifics of our currently 
                            enrolling NextCOVE COVID-19 research study, please click below.
                            </p>
                                <br /><br />
                                <div onClick={() => nav('/about')} className='learn-more-button p-[13px] desktop'>
                                    <span>click here to learn more</span>
                                </div>
                        </Col>
                        <Col lg={12} xl={12} xxl={12}>
                            <LazyLoadImage alt='About flu vaccine' className='faq-about-flu-vaccine' src={faqLearnMorePic} />
                        </Col>
                    </Row>
                    <div className='faq-items'>
                        <Collapse 
                            bordered={false}
                            accordion={false}
                            expandIcon={({ isActive }) => <img width={30} alt='expand icon' src={downArrow}/>}
                        >
                            {faqitems.map((f, i) => (
                                <Collapse.Panel key={i} className='faq-header' header={f.title}>
                                    {f.description}
                                </Collapse.Panel>  
                            ))}
                        </Collapse>
                    </div>
                </div>
            </section>
            <JoinUs />
            <section className='references-section'>
                
            </section>
            {showLoading && <img width={2} alt='loading' src={hero} onLoad={handleLoad} />}
        </div>
    )
};

export default FAQ;