import { createContext, useState } from 'react';

export const Context = createContext();

const ContextProvider = (props) => {
    const defaultIsAuthenticated = sessionStorage.getItem('prevAuth') === 'true';

    const [user, setUser] = useState();
    const [progress, setProgress] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(defaultIsAuthenticated);
    const [externalId, setExternalId] = useState();
    const [source, setSource] = useState();

    const doConversion = () => {
        if (window.location.hostname.toLowerCase() === 'cvsapol1study.com')
            console.log('test')
            // gtag('event', 'conversion', {
            //     'allow_custom_scripts': true,
            //     'session_id': '[SessionID]',
            //     'send_to': 'DC-6615255/cvsna0/clini0+per_session'
            // });
    }

    return (
        <Context.Provider
            value={{
                user,
                setUser,
                progress,
                setProgress,
                isAuthenticated,
                setIsAuthenticated,
                externalId,
                setExternalId,
                doConversion,
                source,
                setSource
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default ContextProvider;