import { Affix, Col, Progress, Row } from 'antd';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import logo from '../../images/logo@2x.png';

const Footer = () => {

    const { progress } = useAuth();

    const navigate = useNavigate();

    const location = useLocation();

    const showDisclaimer = location.pathname === '/';

    const nav = (path) => {
        navigate(path);
    }

    const progressbar = !!progress && (
        <Affix offsetBottom={20}>
            <Progress 
                percent={progress}
                className='max-w-md progress-wrapper'
                strokeLinecap='butt' 
                trailColor='pink'
                strokeColor='green'
            />
        </Affix>
    );

    return (
        <>
            {progressbar}
            <section className='footer-section'>
                <div className='content'>
                    <Row>
                        <Col xs={24} xm={24} md={12}>
                            <button
                                className="leading-relaxed inline-block py-2 whitespace-nowrap uppercase text-white"
                                onClick={() => nav('/')}
                            >
                                <img alt='CVS logo' src={logo} className='logo' />
                            </button>
                        </Col>
                        <Col xs={24} xm={24} md={12}>
                            <div
                                className={
                                "lg:flex flex-grow items-center 2xl:height-[113px] xl:height-[113px]" +
                                (true ? " flex" : " hidden")
                                }
                                id="example-navbar-danger"
                            >
                                <ul className="flex flex-col lg:flex-column list-none lg:ml-auto transition-colors duration-300 ">
                                    <li className="nav-item">
                                        <button
                                            className="px-3 py-2 flex items-center uppercase leading-snug hover:opacity-75" 
                                            onClick={() => nav('/')}
                                        >
                                            <span className="ml-2">home</span>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className="px-3 py-2 flex items-center uppercase leading-snug hover:opacity-75" 
                                            onClick={() => nav('/about')}
                                        >
                                            <span className="ml-2 text-left">about the nextcove covid-19 study</span>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className="px-3 py-2 flex items-center leading-snug hover:opacity-75" 
                                            onClick={() => nav('/faqs')}
                                        >
                                            <span className="ml-2">FAQs</span>
                                        </button>
                                    </li>
                                
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
                
            </section>
            {showDisclaimer && <section className='disclaimer-section'>
                <div className='content'>
                    <p className='header'>disclaimer</p>
                    <div className='info'>
                        <p>
                        FOR THE SAFETY OF CLINICAL STUDIES: The safety of an investigational 
                        product is the responsibility of the study sponsor and not the investigators, 
                        CVS Health® or its subsidiaries, affiliates or managed entities. 
                        CVS Health® or its subsidiaries, affiliates or managed entities 
                        cannot guarantee any particular outcome or benefit from a research 
                        study. Choosing to participate in a research study is a personal 
                        decision. Before participating in a research study, you should 
                        know all of the risks and potential benefits and should talk to 
                        your health care provider and other trusted advisors. 
                        &nbsp;<a className='underline hover:underline hover:text-black' href='https://www.clinicaltrials.gov/?DisableWebtrends=Y&DisableBluecore=Y'>Learn more 
                        about participating in research studies</a>. 
                        
                        </p>
                        <br />
                        <p>
                        Nothing in the content of this message should be considered, or used as a 
                        substitute for, medical advice, diagnosis, treatment or a product offering 
                        by CVS Health or its subsidiaries, affiliates, or managed entities. The information does not constitute the 
                        practice of any medical, nursing or other professional health care advice, 
                        diagnosis or treatment. All medical decisions are exclusively the 
                        responsibility of the treating physician. 
                        </p>
                        <br />
                        <p>The research study is voluntary and is not a part of your health plan benefits. </p>
                        <br />
                        <p>
                        CVS Health Clinical Trial Services™ is sending this message to you on behalf of one or 
                        more of the following CVS Health® covered entities with which you have a relationship: 
                        CVS Pharmacy®, Aetna®, MinuteClinic®.

                        </p>
                    </div>
                </div>
            </section>}
            <div className='text-center' style={{ background: 'white', minHeight: 100, padding: 20}}>
                <p><a className='underline hover:underline hover:text-black' href='https://www.cvs.com/help/privacy_policy.jsp' target={'_blank'}>Privacy Policy</a> • ©2023 • All Rights Reserved</p>
                <p>This website does not replace the informed consent process. This website does not replace the advice of your doctor.</p>
            </div>
        </>
    );
}

export default Footer;