/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Space, Typography } from 'antd';
import useAuth from '../hooks/useAuth';

const { Title } = Typography;

const Login = () => {

    const location = useLocation();

    const { isAuthenticated, setIsAuthenticated } = useAuth();

    const nav = useNavigate();

    const [pass, setPass] = useState();

    const handlePass = (e) => setPass(e.target.value);

    const verify = () => {
        if (pass === 'wR073^%EdUFh') {
            setIsAuthenticated(true);
            if (!!location.state?.route)
                nav(location.state?.route);
            else
                nav('/');
            
            sessionStorage.setItem('prevAuth', 'true');
        }
    }
    
    return (
        <div class='landing animated animatedFadeInUp landing-fade-delay m-auto text-center'>
            <section>
                <Space direction='vertical'>
                    <Title>
                        Site Locked
                    </Title>
                    <Input type='password' value={pass} onChange={handlePass} placeholder='password' />
                    <Button type='primary' onClick={verify}>Unlock</Button>
                </Space>
            </section>
        </div>
    )
};

export default Login;