/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import hero from '../images/hero@2x.png';
import heart from '../images/heart-icon@2x.png';
import doIQualify from '../images/Do I Qualify Button@2x.png';
import bullet from '../images/bullet-point@2x.png';
import aboutPhoto from '../images/about-flu-vaccine-photo@2x.png';
import impactsOfAsthma from '../images/impact-covid@2x.png';
import drLaptop from '../images/doctor-laptop-frame-icon.svg'
import testTube from '../images/test-tube-icon.svg'
import timeLaptop from '../images/time-latptop-icon@2x.png'
import beakerDna from '../images/beaker-dna-icon.svg'
import howToParticipate from '../images/how-to-participate-photo@2x.png'
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useProgressiveImage from '../hooks/useProgressiveImage';
import JoinUs from './JoinUs';
import Risk from './RiskSection';
import useAuth from '../hooks/useAuth';
import { gtag } from 'ga-gtag';
import '../styles/Landing.css';

const Landing = () => {

    const { doConversion } = useAuth();
    const navigate = useNavigate();

    const nav = (page) => {
        // if (page === questionnaireLink)
        //     doConversion();
            
        navigate(page);
    };

    const questionnaireLink = '/questionnaire';

    //const heroSrc = useProgressiveImage(hero);
    
    //const [heroReady, setHeroReady] = useState(false);

    useEffect(() => {
        // if (window.location.hostname.toLowerCase().endsWith('cvsapol1study.com'))
        //     gtag('event', 'conversion', {
        //         'allow_custom_scripts': true,
        //         'send_to': 'DC-6615255/cvsna0/clini00+standard'
        //     });
    }, []);

    // useEffect(() => {
    //     if (!!heroSrc)
    //         setTimeout(() => {
    //             setHeroReady(true);
    //         }, 100);
    // }, [heroSrc]);
    
    return (
        <div class='landing animated animatedFadeInUp landing-fade-delay'>
            <section 
                className='banner-section'
                style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 1,
                }}
            >
                <div className='content'>
                    <p className='header'>
                    Help Researchers Learn More About Investigational COVID-19 Booster Vaccines.
                    </p>
                    <p className='info'>
                    Participate in an investigational booster vaccine clinical trial.
                    </p>
                    <p>
                        <div onClick={() => nav('/questionnaire')} className='conversion-button do-i-qualify-button p-[13px]'>
                            <span>Pre-Qualify Now</span>
                        </div>
                    </p>
                </div>
            </section>
            <Risk />
            <section 
                className='about-section'
            >
                <div className='content'>
                    <Row gutter={17}>
                        <Col xs={0} sm={0} md={0} lg={10} xl={9} xxl={8}>
                            <LazyLoadImage alt='About photo' className='about-photo' src={aboutPhoto} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={14} xl={15} xxl={16}>
                            <p className='header'>About the NextCOVE COVID-19 Study</p>
                            <hr />
                            <div className='info'>
                                <p>
                                The NextCOVE study is looking for adolescents and adults 12 years of age and older who have 
                                received a COVID-19 vaccine. Participants cannot have a total of 6 vaccine and booster 
                                shots combined. The study is researching an investigational bivalent vaccine that may 
                                protect people from getting sick if they come into contact with the virus that causes 
                                COVID-19 and multiple variants, including the Omicron variant. 
                                </p>
                                <br />
                                <p>
                                The screening visit will determine qualification, vaccination day is when participants 
                                will be randomized and receive the investigational vaccine, and the other visits will 
                                be follow-ups. All study related health tests and medical checks will be provided by 
                                the study team at no cost. Participants who decide to join the NextCOVE study will be 
                                separated into groups depending on age. 
                                </p>
                                <br />
                                <p>
                                Learn more and find out if you or a loved one qualify today.
                                </p>
                            </div>
                                <br />
                                <div onClick={() => nav('/questionnaire')} className='conversion-button do-i-qualify-button p-[13px]'>
                                    <span>Pre-Qualify Now</span>
                                </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                            <br /><br />
                            <LazyLoadImage alt='About photo' className='about-photo' src={aboutPhoto} />
                        </Col>
                    </Row>
                </div>
            </section>
            <section 
                className='about-genetic-tests-section'
            >
                <div className='content'>
                    <Row gutter={17}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <p className='header'>Impacts of COVID-19</p>
                            <div className='info'>
                            COVID-19 variants have emerged and are likely to continue to emerge. Some of 
                            them have developed resistance to previous vaccines and boosters. Despite 
                            advances in vaccine and booster vaccine research for COVID-19 protection, 
                            the concern of spread continues as the strains mutate. It's important to 
                            continue research into potential new vaccines. 
                                <div className='links'>
                                    <br />
                                    <div onClick={() => nav('/about')} className='learn-more-button p-[13px]'>
                                        <span>Learn More</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className='spacer'></div>
                            <LazyLoadImage alt='About Genetics' className='impacts-of-covid-photo' src={impactsOfAsthma} />
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='qualified-section'>
                <span className='content'>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <img alt='CVS heart logo' className='heart-logo' src={heart} />
                            <p className='header'>Qualified Participants May Have Access to the Following:</p>
                            <div onClick={() => nav('/about')} className='learn-more-button p-[13px] desktop'>
                                <span>Learn More</span>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className='card'>
                                    <LazyLoadImage src={timeLaptop} alt='Compensation Graphic' />
                                    <p className='header min-h-[60px] grid content-center'>Compensation</p>
                                    <hr />
                                    <p className='pt-4 info'>Receive compensation for time may be available</p>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className='card'>
                                    <LazyLoadImage src={beakerDna} alt='Contribute to Research Graphic' />
                                    <p className='header min-h-[60px] grid content-center'>No-Cost Study</p>
                                    <hr />
                                    <p className='pt-4 info'>Receive access to a no-cost study</p>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className='card'>
                                    <LazyLoadImage src={drLaptop} alt='Health Information Graphic' />
                                    <p className='header min-h-[60px] grid content-center'>Clinical Research Care Access</p>
                                    <hr />
                                    <p className='pt-4 info'>
                                    Receive access to clinical research medical professionals for trial-related visits
                                    </p>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className='card'>
                                    <LazyLoadImage src={testTube} alt='Contribute to Research Graphic' />
                                    <p className='header min-h-[60px] grid content-center'>Product</p>
                                    <hr />
                                    <p className='pt-4 info'>Receive access to an investigational booster vaccine</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div onClick={() => nav('/about')} className='learn-more-button p-[13px] mobile'>
                        <span>Learn More</span>
                    </div>
                </span>
            </section>
            <section 
                className='how-to-participate-section'
            >
                <div className='content'>
                    <p className='header'>Find Out How to Pre-Qualify</p>
                    <hr />
                    <Row gutter={17}>

                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <LazyLoadImage alt='How to Participate' className='how-to-participate-hero' src={howToParticipate} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <div className='info'>
                        Participants may be eligible for the NextCOVE study if they:
                                <br /><br />
                                <div className='list'>
                                    <Row gutter={[16,16]}>
                                        <Col span={2}><img alt='bullet' className='bullet' src={bullet} /></Col>
                                        <Col span={22}>Are 12 years of age or older</Col>
                                        <Col span={2}><img alt='bullet' className='bullet' src={bullet} /></Col>
                                        <Col span={22}>Have previously received a COVID-19 vaccine</Col>
                                        <Col span={2}><img alt='bullet' className='bullet' src={bullet} /></Col>
                                        <Col span={22}>
                                        If over 18 years old, have also received at least 1 booster dose of COVID-19 vaccine 
                                        </Col>
                                        <Col className='additional-info' span={24}>
                                        The booster doses are not required for participants younger than 18 years of age. 
                                        All trial participants will receive care from the trial doctor while in 
                                        the NextCOVE Study. Parental consent is required for those under 18 years of age. 
                                        Additional criteria may apply.
                                        </Col>
                                    </Row>
                                    <div onClick={() => nav('/questionnaire')} className='conversion-button do-i-qualify-button p-[13px]'>
                                        <span>Pre-Qualify Now</span>
                                    </div>
                                </div>
                            </div>
                                <br /><br />
                                
                        </Col>
                    </Row>
                </div>
            </section>
            <JoinUs />
        </div>
    )
};

export default Landing;