/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import '../styles/Risk.css';
const Risk = ({ header = null, subTitle = null }) => {

    return (
        <section 
            className='risk-section'
            style={{
                background: `solid red 0% 0% no-repeat padding-box`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <div className='content'>
                <p className='header'>
                    {header ?? <span>The Fight Against COVID-19 isn't Over.</span>}
                </p>
                <p className='info'>
                    {subTitle ?? <span>You or a loved one may be eligible to receive access to an investigational booster vaccine for the potential continued prevention of COVID-19 by participating in a clinical trial.</span>}
                </p>
            </div>
        </section>
    )
};

export default Risk;