/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import heart from './../images/white-heart.png';
import useAuth from '../hooks/useAuth';

const ScreenQualified = () => {

    const { setUser, setProgress } = useAuth();

    setUser();

    useEffect(() => {
        //setProgress(100);
    }, [])

    return (
        <div className='animated animatedFadeInUp fadeInUp my-12 screener-banner'>
            <div className='header'>
                Congratulations!
            </div>
            <div className='sub-header'>
                <p>
                You or your child have passed the initial questionnaire to determine if you are eligible to move on to the next 
                stage of prescreening for the study. Your (their) information will now be forwarded to the study team.
                </p>
                <br />
                <p>
                If you or the participant are not immediately connected with the site after completion of this screening, please expect a call from the site's study team within the next 2-3 business days.                </p>
            </div>
            <hr />
            <div className='info'>Thank you and have a great day.</div>
            <img alt='CVS heart logo' className='white-heart pb-10' src={heart} />
        </div>
    )
};

export default ScreenQualified;